import { get } from "lodash";

declare global {
  interface Window {
    bpmeMessageHandler: any;
    bpme: any;
    webkit: { 
      messageHandlers: any;
    };
    jwt: string;
  }
}

export const postMessageToBPme = (data: any): void => {
  const iosHandler = get(window, `webkit.messageHandlers.bpme`, null);
  const androidHandler = get(window, "bpmeMessageHandler", null);

  if (iosHandler) {
    iosHandler.postMessage({
      ...data,
    });
  } else if (androidHandler) {
    androidHandler.postMessage(
      JSON.stringify({
        ...data,
      })
    );
  } else {
    console.log("No handler found");
  }
};

export const loadBPmeUserInfo = (setReactToken: (token: string) => void, setUserInfo: (data: any) => void) => {
  window.bpme = {
    publish: {},
  };
  window.bpme.publish.responseJwt = (jwt: string) => {
    setReactToken(jwt);
  };

  const payload = { action: "bpme.jwt.request", payload: 120 };
  postMessageToBPme(payload);
};


export const loadPortalUserInfo = (
  setUserAuthToken: (token: string) => void,
  setUserInfo: (data: any) => void
) =>
{
  setUserAuthToken(window.jwt_token);
  //setUserInfo(window.jwt_token);
};