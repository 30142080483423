import { createStore } from "botframework-webchat";

const Extensions = ["pdf", "xml", "json", "png"];

export const getStore = (setShowNewMessageState: any) => {
  return createStore({}, ({ dispatch }: any) => (next: any) => (action: any) => {

    if (action.type === "WEB_CHAT/SEND_MESSAGE") {
          if (
            action.payload.text.match(
              /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
            )
          ) {
            let num = action.payload.text.match(
              /(\d *){8,19}|(\d[-]*){8,19}|(\d[-]* *){8,19}|(\d\/* *){8,19}|(\d\\* *){8,19}|(\d\\*\/*[-]* *){8,19}/
            );
            let match =
              /^(?:3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})[0-9]{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}))$/;
            let rawStr = num[0].replace(/\s/g, "").replace(/-/g, "");
            let matched = rawStr.match(match);
            if (matched && matched.length > 0) {
              action.payload.text = action.payload.text.replace(
                num[0],
                "XXXX "
              );
            }
          }

          if (action.payload.text.length > 1024) {
            action.payload.text =
              "Sorry, I can not accept more that 1024 characters at a time.";
          }
        }

        if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
          dispatch({
            type: "WEB_CHAT/SEND_EVENT",
            payload: {
              name: "webchat/join",
              value: {
                language: window.navigator.language,
              },
            },
          });

          
          const d1 = document.querySelector(
            ".webchat__basic-transcript__scrollable"
          );
          let d2 = document.createElement("div");
          d2.innerHTML = `<div class="bot-loading">Loading</div>`;
          d1?.appendChild(d2);

            let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
              "button[title='Upload file']"
          );

          if (fileUploadIconBtn)
          {
            fileUploadIconBtn.disabled = true;
            fileUploadIconBtn.parentElement!.style.width = '0px';
          }

          let fileUploadInput = document.querySelector<HTMLInputElement>(
            ".webchat__upload-button--file-input"
          );
          
          if (fileUploadInput !== null)
          {
            fileUploadInput?.removeAttribute("multiple");
            fileUploadInput.disabled = true;
            const fileTypes = [
              "image/png",
              "image/jpeg",
              "image/jpg",
              "application/pdf",
            ];
            fileUploadInput.setAttribute("accept", fileTypes.join(","));

            fileUploadInput.addEventListener("change", (e) =>
            {
              var isLC = fileUploadInput?.getAttribute("data-isLiveChat");

              if (
                fileUploadInput?.value !== "" &&
                
                fileUploadInput!.files!.length > 0 &&
                fileUploadInput!.files![0].size > 4183818
              )
              {
                fileUploadInput!.value = "";
                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    name: isLC
                      ? "livechat/file_size_issue"
                      : "webchat/file_size_issue",
                  },
                });
                e.preventDefault();
                return;
              }

              if (
                fileUploadInput!.value !== "" &&
                fileUploadInput!.files!.length > 0 &&
                !fileTypes.includes(fileUploadInput!.files![0]?.type)
              )
              {
                fileUploadInput!.value = "";
                dispatch({
                  type: "WEB_CHAT/SEND_EVENT",
                  payload: {
                    name: isLC
                      ? "livechat/file_type_issue"
                      : "webchat/file_type_issue",
                  },
                });
                e.preventDefault();
                return;
              }
            });
          }
        }

        if (
          action &&
          action.payload &&
          action.payload.activity &&
          action.payload.activity.type === "event" &&
          action.payload.activity.name === "WEB_CHAT/DISABLE_SUBMIT_BUTTON"
        ) {
          let submitButtonId = action.payload.activity.value;
      const submitBtn = document?.getElementById("#" + submitButtonId + " button") as HTMLButtonElement;
          if (submitBtn) {
            submitBtn?.setAttribute("disabled", "disabled");
            submitBtn!.style!.visibility = "hidden";
          }
        }

        if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
          let lis = document.getElementsByClassName(
            "webchat__basic-transcript__activity"
          );

          let element = document.getElementsByClassName("bot-loading")?.[0];
          if (element && lis.length > 1) {
            element.remove();
          }

          if (action.payload.activity.from.role === "bot") {
            if (
              action.payload.activity.name === "WEB_CHAT/ENABLE_ATTACHMENT_ICON"
            ) {
              const fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
                "button[title='Upload file']"
              );
              const fileUploadInput = document.querySelector<HTMLInputElement>(
                ".webchat__upload-button--file-input"
              );

              fileUploadIconBtn!.disabled = false;
              fileUploadInput!.disabled = false;
              fileUploadIconBtn!.parentElement!.style.removeProperty("width");

              if (
                action.payload.activity.islivechat &&
                action.payload.activity.islivechat === "true"
              )
                fileUploadInput!.setAttribute("data-isLiveChat", "true");

              if (
                action.payload.activity.islivechat &&
                action.payload.activity.islivechat === "true"
              )
                fileUploadInput!.setAttribute("data-isLiveChat", "true");

              fileUploadIconBtn!.parentElement!.style.removeProperty("width");
            }
          }

          if (
            action.payload.activity.name === "WEB_CHAT/DISABLE_ATTACHMENT_ICON"
          ) {
            let fileUploadIconBtn = document.querySelector<HTMLButtonElement>(
              "button[title='Upload file']"
            );
            let fileUploadInput = document.querySelector<HTMLInputElement>(
              ".webchat__upload-button--file-input"
            );
            fileUploadIconBtn!.disabled = true;
            fileUploadInput!.disabled = true;
            fileUploadInput!.removeAttribute("data-isLiveChat");
            fileUploadIconBtn!.parentElement!.style.width = '0';
          }
          // Disbable submit form button
          if (action.payload.activity.name === "FORM_SUBMITTED") {
            const getElementButtonClass = document
              .getElementsByClassName(
                "webchat__basic-transcript__transcript"
              )?.[0]
              .querySelectorAll("button");
            for (let n = 0; n < getElementButtonClass.length; n++) {
              let buttonInnerText =
                getElementButtonClass[n].textContent ||
                getElementButtonClass[n].innerText;
              if (
                buttonInnerText === "Confirm" ||
                buttonInnerText === "Submit"
              ) {
                getElementButtonClass[n].disabled = true;
              }
            }
          }
        }
        
        if (action.type === "DIRECT_LINE/CONNECT_REJECTED") {
          let element = document.getElementsByClassName(
              "webchat__basic-transcript__transcript"
          )?.[0];
          element.classList.remove("bot-loading");
      }
        return next(action);
      }
  );
};
