import React, { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useAtom } from "jotai";
import { userAuthTokenAtom } from "../atoms";

const API_URL = process.env.REACT_APP_API_URL;
const AZURE_AD_APP_SCOPE = process.env.REACT_APP_AZURE_AD_APP_SCOPE;


const AzureAdToken = () => {
  const isInitialized = useRef(true);

  const { instance, accounts } = useMsal();
  const [reactToken, setReactToken] = useAtom(userAuthTokenAtom);

  useEffect(() => {
    const getReactToken = async () => {

      const firstAccount = instance.getActiveAccount() || accounts[0];
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: [AZURE_AD_APP_SCOPE || ""],
        account: firstAccount!,
      });

      let reactTokenResponse = await axios.get(
        `${API_URL}/token/get-react-token`,
        {
          headers: {
            accept: `*/*`,
            "Content-Type": "application/json",
            authorization: `bearer ${tokenResponse.accessToken}`,
          },
        }
      );

      const token = reactTokenResponse.data.token;
      setReactToken(token);
    };

    if (isInitialized.current)
    {
      isInitialized.current = false;
      getReactToken();
    }
  }, []);

  return <></>;
};

export default AzureAdToken;
