import React from "react";
import { useEffect, useRef, useState, useCallback } from "react";
import { useAtom } from "jotai";
import { botLoadedAtom, directLineTokenAtom,showNewMessageAtom ,minimizeBotAtom,userDataAtom} from "../atoms";
import './BPmePortalChatBox.scss';
import classNames from "classnames";
import { useCookies } from "react-cookie";
import {getCookieOptions} from './App';
const USER_DATA_COOKIE = "bpmeBotUserData";


type ChatBoxProps = {
  className?: string;
  children: React.ReactNode;
}
let HOST_URL = process.env.REACT_APP_HOST_URL;

export const BPmePortalChatBox = ({  className,children }: ChatBoxProps) =>
{
  
  const [chatBubbleAnimation, setChatBubbleAnimation] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [reload, setReload] = useState(false);
  const [side, setSide] = useState("right");
  const [botLoading, setBotLoading] = useState(!botLoadedAtom);
  const [showNewMessage, setShowNewMessage] = useAtom(showNewMessageAtom);
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);
  const [minimized, setMinimized] = useAtom(minimizeBotAtom);
  const [botLoaded, setBotLoaded] =useAtom(botLoadedAtom);
  const [userData, setUserData] = useAtom(userDataAtom);

  const [cookies, setBpChatBotCookie] = useCookies([
    USER_DATA_COOKIE,
    
  ]);

useEffect(()=>{

    if (chatStarted === true) {
        setChatBubbleAnimation(false);
    }  else {
        setTimeout(() => {
            setChatBubbleAnimation(true);
        }, 2500);

        setTimeout(() => {
            setChatBubbleAnimation(false);
        }, 62500);
    }
    
},[]);

  const handleMaximizeButtonClick = async () => {
    
    if (directLineToken) {
        setMinimized(false);
        setShowNewMessage(false);
        const botuserData = cookies.bpmeBotUserData ?? {};
        if (!chatStarted && !botuserData.chatStarted) {     
            
            botuserData.chatStarted = true;
            setBpChatBotCookie(USER_DATA_COOKIE, botuserData, getCookieOptions());
            setUserData(botuserData);
            setChatStarted(botuserData.chatStarted);
        
        }
        setBotLoaded(true);
        botuserData.minimized = false;
        setBpChatBotCookie(USER_DATA_COOKIE, botuserData, getCookieOptions());
        setUserData(botuserData);
    }
};

const handleCloseButtonClick = useCallback(async () => {
  setReload(true);
}, [setReload]);



const handleSwitchButtonClick = useCallback(async () => {
  setSide(side === "left" ? "right" : "left");
}, [setSide, side]);


const handleMinimizeButtonClick = async () => {
  const botUserData = cookies?.bpmeBotUserData ?? {};
  setMinimized(true);
  setShowNewMessage(false);
  if(botUserData){
    botUserData.minimized = true;
    setBpChatBotCookie(USER_DATA_COOKIE, botUserData, getCookieOptions());
  }
};


useEffect(() => {
    if (reload && botLoaded) {
        setBotLoaded(false);
        setReload(false);
    }
    return () => {
       
        if (botLoaded) {
            setBotLoading(false);
            setBotLoaded(true);
            if (chatStarted) {
                setMinimized(false);
            }
        }
    };
}, [setReload, reload, chatStarted]);

  return (
    <div
        className={classNames("minimizable-web-chat")}
        data-testid='web-chat-box'>
        {minimized && (
            <button
                data-testid='bubble-button'
                className={
                    classNames("maximize") &&
                    classNames("container-bubble", { sample: chatBubbleAnimation })
                }
                
                onClick={handleMaximizeButtonClick}>
                <div className='image' id='img'>
                    {showNewMessage && (
                       
                        <span className='circle'>
                            <div data-title='.dot-flashing'>
                                <div className='stage'>
                                    <div className='dot-flashing'></div>
                                </div>
                            </div>
                        </span>
                    )}
                </div>
                <span className='overlay'>
                    <img
                        src={HOST_URL + "assets/PillOnSlider.svg"}
                        className={classNames("animation", {
                            "slider": !chatBubbleAnimation,
                        })}
                        alt=''
                    />
                </span>
            </button>
        )}

        {botLoaded && (
            <div
                data-testid='chat-box'
                className={classNames(
                    side === "left" ? "chat-box left" : "chat-box right",
                    minimized ? "hide" : ""
                )}>
                <header data-testid='chat-header'>
                    {botLoading && (
                        <button onClick={handleCloseButtonClick}>
                            <div className='reloadBtn'>Reload</div>
                        </button>
                    )}
                    <div className='filler' data-testid='chat-title'>
                        <span className='bot-title'>
                            {"BP Rewards virtual assistant"}
                        </span>
                    </div>
                    <button
                        data-testid='chat-switch-btn'
                        className='switch'
                        onClick={handleSwitchButtonClick}>
                        <span className='ms-Icon ms-Icon--Switch' />
                    </button>
                    <button
                        data-testid='chat-minimize-btn'
                        className='minimize'
                        onClick={handleMinimizeButtonClick}>
                        <span className='ms-Icon ms-Icon--ChromeMinimize' />
                    </button>
                </header>
                { children }
            </div>
        )}
    </div>
);
};

export default BPmePortalChatBox;


