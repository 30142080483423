import React, { useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "../auth/auth.config";
import { SignIn } from "../auth/SignIn";
import AzureAdToken from "./AzureAdToken";
import { useAtom } from "jotai";
import { userAuthTokenAtom, userInfoAtom } from "../atoms";

//if we are on a browser, show SSO login

type AppProps = {
  children: React.ReactNode;
  userInfoLoader: ((setReactToken: (token: string) => void, setUserInfo: (data: any) => void) => void);
  isSSORequired: boolean
}

const AuthWrapper = ({ children, userInfoLoader, isSSORequired }: AppProps) =>
{
  const [userAuthToken, setUserAuthToken] = useAtom(userAuthTokenAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  
  useEffect(() =>
  {
    
    if (!isSSORequired)
    {
      userInfoLoader(setUserAuthToken, setUserInfo);
    }
  }, []);
  if (!isSSORequired) {
    return (
      <>
        { userAuthToken && children }
      </>
    );
  } else {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <MsalProvider instance={msalInstance}>
        <>
          <AuthenticatedTemplate>
            <AzureAdToken />
            { userAuthToken && children}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignIn />
          </UnauthenticatedTemplate>
        </>
      </MsalProvider>
    );
  }
};

export default AuthWrapper;