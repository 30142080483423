import "./App.scss";
import "../fabric-icons-inline.css";
import { useAtom } from "jotai";
import BPmePortalChatBox from "./BPmePortalChatBox";
import { useEffect, useRef, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import { userDataAtom, directLineTokenAtom, showNewMessageAtom, userAuthTokenAtom, botLoadedAtom, minimizeBotAtom } from "../atoms";
import { getStore } from "./store";
import WebChat from "./WebChat";
import { fetchDirectLineTokenAsync, fetchReconnectTokenAsync } from "../api";
import { UserData } from "../types";
const TOKEN_COOKIE = "bpChatBotToken";
const USER_DATA_COOKIE = "bpmeBotUserData";

export const getCookieOptions = () =>
{
  let d = new Date();
  d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
  return {
    path: "/",
    sameSite: true,
    secure: true,
    expires: d,
  };
};

const App = () =>
{

  const isInitialized = useRef<boolean>(true);
  const botStore = useRef<any>(null);

  const [userData, setUserData] = useAtom(userDataAtom);
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);
  const [showNewMessage, setShowNewMessage] = useAtom(showNewMessageAtom);

  const [userName, setUsername] = useState<string>("Guest");
  const [reactToken] = useAtom(userAuthTokenAtom);
  const [loaded, setLoaded] = useAtom(botLoadedAtom);
  const [minimizeBot,setminimizeBotAtom] = useAtom(minimizeBotAtom);

  const [cookies, setBpChatBotCookie, removeBpChatBotCookie] = useCookies([
    TOKEN_COOKIE,
    USER_DATA_COOKIE,
  ]);

  const createLocalStore = () =>
  {
    const store = () =>
    {
      return getStore(setShowNewMessage);
    }

    botStore.current = store();
  };

  const updateUserDataCookie = (data: any) =>
  {
    setBpChatBotCookie(
      USER_DATA_COOKIE,
      {
        ...cookies.bpmeBotUserData,
        ...data,
      },
      getCookieOptions()
    );
  };

  const fetchFreshToken = useCallback(async () =>
  { 
    try
    {
      const reactTokenResponse = await fetchDirectLineTokenAsync({}, String(reactToken));
      setDirectLineToken(reactTokenResponse.token);
      const userData = {
        conversationId: reactTokenResponse.conversationId,
        user: reactTokenResponse.refUserId,
        username: reactTokenResponse.username,
        chatStarted: false,
        refUserId: reactTokenResponse.refUserId,
        minimized:true
      } as UserData;

      setUserData(userData);
      setBpChatBotCookie(TOKEN_COOKIE, reactTokenResponse.token, getCookieOptions());
      updateUserDataCookie(userData);
      setUsername(reactTokenResponse.username);
      setLoaded(true);
      setminimizeBotAtom(true);
    } catch (error) {
      console.log(error);
    }

  }, [reactToken]);
  const fetchReconnectToken = useCallback(async () =>
  {
    try {
      const cookieUserData = cookies.bpmeBotUserData;
      const cToken = cookies.bpChatBotToken;
      if (cToken && userData)
      {
        const reactTokenResponse = await fetchReconnectTokenAsync(cToken, cookieUserData);
        setDirectLineToken(reactTokenResponse.token);
        const userData = {
          conversationId: reactTokenResponse.conversationId,
          user: reactTokenResponse.refUserId,
          username: reactTokenResponse.username,
          chatStarted: cookieUserData.chatStarted,
          refUserId: reactTokenResponse.refUserId,
          minimized : cookieUserData.minimized
        } as UserData;
        
        setUserData(userData);
        setBpChatBotCookie(TOKEN_COOKIE, reactTokenResponse.token, getCookieOptions());
        updateUserDataCookie(userData);
        setUsername(reactTokenResponse.username);
        setLoaded(true);
        setminimizeBotAtom(userData.minimized);

      }else{
        fetchFreshToken();
      }
    } catch (error: any) {
      if (error.response)
      {
        removeBpChatBotCookie(TOKEN_COOKIE);
        removeBpChatBotCookie(USER_DATA_COOKIE);
      } else if (error.request)
      {
        console.log(error.request);
      } else
      {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      fetchFreshToken();
    }
   }, [directLineToken]);

  useEffect(() =>
  {
    if (isInitialized.current)
    {
      if (cookies.bpChatBotToken && cookies.bpmeBotUserData)
      {
        setDirectLineToken(cookies.bpChatBotToken);
        setUserData(cookies.bpmeBotUserData);
        // if loading from cookies
        isInitialized.current = false;
        fetchReconnectToken();
        createLocalStore();
      } else
      {
        if (reactToken && !directLineToken) 
        {
          isInitialized.current = false;
          fetchFreshToken();
          createLocalStore();
        }
      }
    }

  }, [directLineToken]);

  return (<div data-test-id="app" className="app">
    <BPmePortalChatBox >
      { botStore.current && (
        <WebChat
          data-testid='web-chat'
          className='react-web-chat'
          store={ botStore.current }
          username={ userName } />
      ) }
      </BPmePortalChatBox>
  </div>)
}

export default App;
