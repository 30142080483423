import React, { useMemo } from "react";
import ReactWebChat, {
  createDirectLine,
  createStyleSet,
} from "botframework-webchat";
import "./WebChat.scss";
import { useAtom } from "jotai";
import { botLoadedAtom as botLoadedAtom, directLineTokenAtom } from "../atoms";
let HOST_URL = process.env.REACT_APP_HOST_URL; //URL from env

export const SendButton = () =>
{
  return <img src={ `${ HOST_URL }/assets/bp-icon-airplane-paper-bp-silver.svg` } alt='Send' />;
};

export type WebChatProps = {
  store: any;
  className?: string;
  username?: string;
};

export const WebChat = ({
  store,
  className,
  username,
}: WebChatProps) =>
{
  const [token] = useAtom(directLineTokenAtom);
  const [loaded] = useAtom(botLoadedAtom);

  const directLine = useMemo(() =>
  {
    if (token)
    {
      //console.log(`token: ${ token } direct line initialization`);
      return createDirectLine({ token });
    }

  }, [token]);

 // console.log(store?.getState());


  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: "Transparent",
        bubbleBorderColor: "#e6e6e6",
        bubbleBorderStyle: "solid",
        bubbleBorderWidth: 1,
        bubbleBorderRadius: 5,
        suggestedActionBorderColor: "#007833",
        suggestedActionBorderStyle: "solid",
        suggestedActionBorderWidth: 1,
        suggestedActionTextColor: "#007833",
        suggestedActionBorderRadius: "5px",
        hideUploadButton: true,
        
      }),
    []
  );

  return token && loaded ? (

    <ReactWebChat
      data-testid='web-chat'
      className={ `${ className || "" } web-chat` }
      directLine={ directLine }
      store={ store }
      styleSet={ styleSet }
      username={ username }
      sendTypingIndicator={ true }
    />
  ) : (
      <div className={`${className || ''} bp-loader`} data-testid="chat-loading-box">
          <img src={ HOST_URL+'assets/bp-loader.svg'} data-testid="chat-loading-dots" className="bot-img" alt="logo" />
          <div className="bp-subText" data-testid="chat-loading-text">
            <strong>Loading BP Plus virtual assistant</strong>
          </div>
        </div>
  );
};

export default WebChat;
