import axios from "axios";
import { DirectLineApiResponse, UserData } from "./types";
const API_URL = process.env.REACT_APP_API_URL; //URL from env

export const fetchDirectLineTokenAsync = async (
  userDetails: any,
  reactToken: string
) => {
  
  let apiResponse = await axios.post<DirectLineApiResponse>(
    `${API_URL}/token`,
    {},
    {
      headers: {
        accept: `application/json`,
        "Content-Type": "application/json",
        authorization: `bearer ${reactToken}`,
      },
    }
  );
  return apiResponse.data;
};
export const fetchReconnectTokenAsync = async (
  directLineToken: string,
  userData: UserData
) => {
  const response = await axios.post<DirectLineApiResponse>(
    `${API_URL}/token/reconnect`,
    {
      token: directLineToken,
      conversationId: userData.conversationId,
      username: userData.username,
      user: userData.refUserId,
    },
    {
      headers: {
        accept: `application/json`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
