import { get } from "lodash";

declare global {
  interface Window {
    jwt_token: any;
  }
}

export const isBPmeSSORequired = () => {
  const iosHandler = get(window, `webkit.messageHandlers.bpme`, null);
  const androidHandler = get(window, "bpmeMessageHandler", null);
  return !(iosHandler || androidHandler);
};

export const isPortalSSORequired = () => {
  const HOST_PORTAL_URL = process.env.REACT_APP_API_HOST_PORTAL_URL || false;
  let ssoRequired = true;

  let hostUrl = window.location.host;

  if (HOST_PORTAL_URL !== "" && HOST_PORTAL_URL.toString().includes(hostUrl)) {
    ssoRequired = false;
  }
  return ssoRequired;
};
