import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import AuthWrapper from "./auth/AuthWrapper";
import App from "./Components/App";
import { CookiesProvider } from "react-cookie";
// Use the chat bot specific UserInfo Loader function
import { loadPortalUserInfo } from "./userInfo";
import { isPortalSSORequired } from "./auth/useSso";

// Use the chatbot specific SSO provider function 
const isSSO = isPortalSSORequired();

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
    <AuthWrapper userInfoLoader={loadPortalUserInfo} isSSORequired={isSSO} >
      <App />
      </AuthWrapper>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("bpchatbootroot")
);

reportWebVitals();
