
import { atom } from 'jotai';
import { UserData } from './types';

//const USE_CHAT_BUBBLE = process.env.REACT_APP_USE_CHAT_BUBBLE === "true";

//--------------------------------------------------------------- !USE_CHAT_BUBBLE ? true:

export const showBotAtom = atom<boolean>(false);
export const userAuthTokenAtom = atom<string | null>(null);
export const userInfoAtom = atom<any | null>(null);
export const minimizeBotAtom = atom<boolean>(true);
export const directLineTokenAtom = atom<string|null>(null);
export const showNewMessageAtom = atom<boolean>(false);
export const showAnimationAtom = atom<boolean>(false);
export const userDataAtom = atom<UserData>({
    conversationId: '',
    minimized: false,
    username: '',
    refUserId: '',
    chatStarted: false
});
export const botLoadedAtom = atom<boolean>(false);